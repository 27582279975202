import React from "react";
import { Button } from "@mui/material";

const PricingContent = () => {
  return (
    <div className="app6ix-layout-pricing-section-row">
      <div className="app6ix-layout-pricing-section-media">
        <iframe
          src="https://videos.6ix.com/embed/661964bda988b9ebbedf2ec0"
          title="6ix Pricing   12 Apr 2024 | 6ix Videos"
          frameborder="0"
          autoplay
          allowfullscreen
          muted
        ></iframe>
      </div>
      <div className="app6ix-layout-pricing-section-content">
        <div className="app6ix-layout-pricing-section-content-label">
          6ix for COMPANIES
        </div>
        <div className="app6ix-layout-pricing-section-content-price">
          Pricing starts at $0 per month and scales with usage.
        </div>
        <div className="app6ix-layout-pricing-section-content-investor">
          Reach investors, attract capital and grow your reputation — without
          losing control of the narrative.
        </div>
        <div className="app6ix-layout-pricing-section-content-line"></div>
        <div className="app6ix-layout-pricing-section-content-popular">
          <div className="app6ix-layout-pricing-section-content-popular-heading">
            Popular Features
          </div>
          <div className="app6ix-layout-pricing-section-content-popular-feature">
            Host investor events
          </div>
          <div className="app6ix-layout-pricing-section-content-popular-feature">
            Send investor emails
          </div>
          <div className="app6ix-layout-pricing-section-content-popular-feature">
            Launch investor ad campaigns
          </div>
          <div className="app6ix-layout-pricing-section-content-popular-feature">
            And much, much more!
          </div>
        </div>
        <Button
          variant="outlined"
          href="https://events.6ix.com/account/setting/billing"
          className="app6ix-btn app6ix-btn-primary app6ix-layout-pricing-section-content-btn"
        >
          MANAGE BILLING
        </Button>
      </div>
    </div>
  );
};

export default PricingContent;
