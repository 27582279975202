import React, { useEffect } from "react";
import { useGlobalValue } from "../../GlobalContext";
import { SESSION_AUTH } from "../../helper/auth";
import PricingContent from "./PricingContent";
import "./style.scss";
const Pricing = () => {
  const { isUserSession } = SESSION_AUTH();
  const { setLoginRequest } = useGlobalValue();

  useEffect(() => {
    if (isUserSession) {
      setLoginRequest(0);
    } else {
      setLoginRequest(2);
    }
  }, [isUserSession, setLoginRequest]);
  return (
    <section className="app6ix-layout-pricing-section">
      <div className="app6ix-layout-content-container">
        <div className="app6ix-layout-pricing-section-header">
          <h1 className="app6ix-layout-pricing-section-header-heading">
            Pricing
          </h1>
          <div className="app6ix-layout-pricing-section-header-text">
            6ix builds modular capital markets software that helps you
            <br />
            <span className="text-primary">
              invest in equities, publish your research and raise capital.
            </span>
          </div>
        </div>
        {isUserSession && <PricingContent />}
      </div>
    </section>
  );
};

export default Pricing;
