import { apiCall } from "./servicesCall";

export const USER_SERVICES = {
  loginUser(data) {
    return apiCall("/user/login", data);
  },

  logoutUser(data) {
    return apiCall("/user/logout", data);
  },
  getCurrentUser(data) {
    return apiCall("/user/current", data);
  },
  switchAccount(data) {
    return apiCall("/user/account/switch", data);
  },
  createAccout(data) {
    return apiCall("/user/account/create", data);
  },
  getAccountList(data) {
    return apiCall("/user/account/list", data);
  },
  getUser(data) {
    return apiCall("/user/current", data);
  },
};
