import { CircularProgress } from "@mui/material";

const FullPageLoader = props => {
  return (
    <div
      className={`app6ix-loader ${!props?.normal ? "app6ix-loader-fixed" : ""}  ${props?.dark ? "app6ix-loader-dark" : ""}`}
    >
      <CircularProgress size={90} thickness={3.8} color="inherit" />
    </div>
  );
};

export default FullPageLoader;
