const FooterCols = () => {
  return (
    <div className="app6ix-layout-footer-cols">
      <div className="app6ix-layout-footer-cols-box">
        <div className="app6ix-layout-footer-cols-box-heading">
          Investment Discovery Platform
        </div>
        <div className="app6ix-layout-footer-cols-box-content">
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="https://6ix.com/browse-events">Events </a>
            <a href="https://6ix.com/tv">TV</a>
            <a href="https://6ix.com/live">Live </a>
            <a href="https://6ix.com/investment-discovery/markets">Markets</a>
            <a href="https://6ix.com/magazine">Magazine</a>
          </div>
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="https://6ix.com/companies">Companies </a>
            <a href="https://6ix.com/event-speakers">Speakers</a>
            <a href="https://6ix.com/conferences">Conference</a>
            <a href="https://6ix.com/chat">Chat</a>
          </div>
        </div>
      </div>

      <div className="app6ix-layout-footer-cols-box">
        <div className="app6ix-layout-footer-cols-box-heading">
          Investor Experience Platform
        </div>
        <div className="app6ix-layout-footer-cols-box-content">
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="https://6ix.com/strategy">Strategy </a>
            <a href="https://6ix.com/summits">Summits</a>
            <a href="https://6ix.com/email">Email</a>
            <a href="https://6ix.com/social">Social</a>
          </div>
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="https://6ix.com/ads">Ads </a>
            <a href="https://6ix.com/web">Web</a>
            <a href="https://6ix.com/creative">Creative</a>
            <a href="https://6ix.com/ixo">IXO</a>
          </div>
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="https://6ix.com/analytics">Analytics </a>
            <a href="https://6ix.com/demo">Demo</a>
          </div>
        </div>
      </div>

      <div className="app6ix-layout-footer-cols-box">
        <div className="app6ix-layout-footer-cols-box-heading">
          OFFICE contact
        </div>
        <div className="app6ix-layout-footer-cols-box-content">
          <div className="app6ix-layout-footer-cols-box-content-col">
            <a href="tel:tel:833544-4456">(833) 544-4456 </a>
            <a href="mailto:friends@6ix.com">friends@6ix.com</a>
            <p>
              #202-1200 Bay St., Toronto, <br />
              Ontario, Canada, M5R 2A5
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCols;
