import React from "react";

export function GoogleIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2982_165149)">
        <path
          d="M18.8 10.7083C18.8 10.0583 18.7417 9.43325 18.6333 8.83325H10V12.3791H14.9333C14.7208 13.5249 14.075 14.4958 13.1042 15.1458V17.4458H16.0667C17.8 15.8499 18.8 13.4999 18.8 10.7083Z"
          fill="#4285F4"
        />
        <path
          d="M10.0003 19.6667C12.4753 19.6667 14.5503 18.8459 16.0669 17.4459L13.1044 15.1459C12.2836 15.6959 11.2336 16.0209 10.0003 16.0209C7.61276 16.0209 5.59193 14.4084 4.87109 12.2417H1.80859V14.6167C3.31693 17.6125 6.41693 19.6667 10.0003 19.6667Z"
          fill="#34A853"
        />
        <path
          d="M4.87051 12.2416C4.68717 11.6916 4.58301 11.1041 4.58301 10.5C4.58301 9.8958 4.68717 9.3083 4.87051 8.7583V6.3833H1.80801C1.18717 7.6208 0.833008 9.0208 0.833008 10.5C0.833008 11.9791 1.18717 13.3791 1.80801 14.6166L4.87051 12.2416Z"
          fill="#FBBC05"
        />
        <path
          d="M10.0003 4.97909C11.3461 4.97909 12.5544 5.44159 13.5044 6.34992L16.1336 3.72075C14.5461 2.24159 12.4711 1.33325 10.0003 1.33325C6.41693 1.33325 3.31693 3.38742 1.80859 6.38325L4.87109 8.75825C5.59193 6.59159 7.61276 4.97909 10.0003 4.97909Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_2982_165149">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GoogleIcon;
