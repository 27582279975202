import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { AngleIcon, AppsListIcon } from "../../icons";

const HeaderApps = () => {
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = event => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };
  const APPS_LIST = [
    {
      icon: "home",
      title: "homepage",
      text: "Grow your net worth & market cap",
      link: "https://app.6ix.com",
    },
    {
      icon: "events",
      title: "events",
      text: "Attend & host investor events",
      link: "https://events.6ix.com",
    },

    {
      icon: "ai",
      title: "ai",
      text: "Generate & analyze text, images and code",
      link: "https://ai.6ix.com",
    },
    {
      icon: "leads",
      title: "leads",
      text: "Launch ad campaigns",
      link: "https://leads.6ix.com",
    },
    {
      icon: "courses",
      title: "courses",
      text: "Study & teach financial courses ",
      link: "https://courses.6ix.com",
    },
    {
      icon: "videos",
      title: "videos",
      text: "Your video in every language",
      link: "https://videos.6ix.com/",
    },
    {
      icon: "websites",
      title: "websites",
      text: "Your superpowered IR website",
      link: "https://websites.6ix.com",
    },
  ];

  return (
    <React.Fragment>
      <div
        className={`app6ix-layout-header-right-item-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-apps-menu-button"
        aria-controls={headerMenuOpen ? "header-apps-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <AngleIcon classes="mb-auto" />
      </div>

      <Menu
        id="header-apps-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-apps-menu-button",
        }}
        className="app6ix-layout-header-right-item-dropdown app6ix-layout-header-right-item-dropdown-apps app6ix-layout-header-right-item-dropdown-space"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          component="nav"
          className="app6ix-layout-header-right-item-dropdown-apps-list"
        >
          {APPS_LIST.map((item, key) => (
            <ListItemButton
              key={key}
              className="app6ix-layout-header-right-item-dropdown-apps-list-item"
              component="a"
              to={item?.link}
              onClick={headerMenuClose}
            >
              <ListItemIcon className="app6ix-layout-header-right-item-dropdown-apps-list-item-icon">
                <AppsListIcon type={item?.icon} />
              </ListItemIcon>
              <ListItemText
                className="app6ix-layout-header-right-item-dropdown-apps-list-item-text"
                primary={item?.title}
                secondary={item?.text}
              />
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </React.Fragment>
  );
};

export default HeaderApps;
