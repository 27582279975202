import React from "react";
import Pricing from "../components/Pricing";
const PricingPage = () => {
  return (
    <div className="app6ix-layout-content-page app6ix-layout-pricing">
      <Pricing />
    </div>
  );
};

export default PricingPage;
