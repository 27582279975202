import React from "react";

export function ClockIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0806 17C13.9466 17 17.0806 13.866 17.0806 10C17.0806 6.13401 13.9466 3 10.0806 3C6.21457 3 3.08057 6.13401 3.08057 10C3.08057 13.866 6.21457 17 10.0806 17Z"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61389 5.80005V10L12.4139 11.4"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClockIcon;
