import React from "react";
export function SocialIcon(props) {
  return props.type === "linkedin" ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#1F1F1F" />
      <path
        d="M8.47099 7.05874C8.47099 7.80838 7.96981 8.45356 7.05852 8.45356C6.20087 8.45356 5.64746 7.84509 5.64746 7.09544C5.64746 6.32603 6.18393 5.64697 7.05923 5.64697C7.93452 5.64697 8.45405 6.28933 8.47099 7.05874ZM5.64746 18.3529V9.17638H8.47099V18.3529H5.64746Z"
        fill="#E9E9E9"
      />
      <path
        d="M9.88255 12.3124C9.88255 11.2218 9.84655 10.3105 9.81055 9.52341H12.3475L12.4731 10.7396H12.5268C12.884 10.1672 13.7593 9.32788 15.2247 9.32788C17.012 9.32788 18.3531 10.5244 18.3531 13.0987V18.3519H15.5296V13.5088C15.5296 12.3836 15.1011 11.5253 14.1178 11.5253C13.3682 11.5253 12.9927 12.1324 12.7957 12.6328C12.7237 12.8121 12.7061 13.062 12.7061 13.3126V18.3519H9.88255V12.3124Z"
        fill="#E9E9E9"
      />
    </svg>
  ) : props.type === "facebook" ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#1F1F1F" />
      <path
        d="M16.9416 5.64697H7.05923C6.28276 5.64697 5.64746 6.28227 5.64746 7.05874V16.9411C5.64746 17.7183 6.28276 18.3529 7.05923 18.3529H12.0004V13.4117H10.5886V11.6646H12.0004V10.2176C12.0004 8.69003 12.8559 7.61709 14.6588 7.61709L15.9315 7.6185V9.45733H15.0865C14.3849 9.45733 14.118 9.98391 14.118 10.4724V11.6653H15.9308L15.5298 13.4117H14.118V18.3529H16.9416C17.718 18.3529 18.3533 17.7183 18.3533 16.9411V7.05874C18.3533 6.28227 17.718 5.64697 16.9416 5.64697Z"
        fill="#E9E9E9"
      />
    </svg>
  ) : props.type === "twitter" ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#1F1F1F" />
      <path
        d="M17.6135 9.16423C17.6197 9.2866 17.622 9.41048 17.622 9.53284C17.622 13.3021 14.7087 17.6469 9.38156 17.6469C7.74499 17.6469 6.2235 17.1748 4.94141 16.3651C5.69975 16.4553 6.46873 16.3976 7.2044 16.1952C7.94007 15.9927 8.628 15.6495 9.22887 15.1852C8.62459 15.1739 8.03898 14.9771 7.55392 14.6221C7.06886 14.2671 6.7086 13.7718 6.5235 13.2054C6.95782 13.2854 7.40481 13.2686 7.83169 13.1563C7.17615 13.0254 6.58673 12.6755 6.16331 12.1657C5.73989 11.6559 5.50852 11.0177 5.50841 10.3592V10.3237C5.91061 10.544 6.36043 10.6665 6.82043 10.681C6.20653 10.2781 5.77195 9.65968 5.60541 8.95205C5.43887 8.24442 5.55291 7.50089 5.92427 6.87323C6.65147 7.75432 7.55881 8.47497 8.58735 8.98835C9.61588 9.50173 10.7426 9.79636 11.8943 9.85312C11.8445 9.63982 11.8196 9.42162 11.8199 9.20275C11.8199 8.82843 11.8948 8.45777 12.0404 8.11194C12.1859 7.76612 12.3992 7.45191 12.6681 7.18726C12.937 6.9226 13.2562 6.71269 13.6075 6.56951C13.9588 6.42633 14.3353 6.35269 14.7156 6.35279C15.1118 6.35215 15.504 6.43168 15.8678 6.58643C16.2315 6.74119 16.5591 6.96787 16.8302 7.25242C17.4786 7.12605 18.1005 6.89208 18.6693 6.56051C18.453 7.22085 18.0003 7.78134 17.3956 8.1377C17.9697 8.07069 18.5304 7.91946 19.0591 7.68902C18.6707 8.26191 18.1811 8.76156 17.6135 9.16423Z"
        fill="#E9E9E9"
      />
    </svg>
  ) : props.type === "instagram" ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#1F1F1F" />
      <path
        d="M15.0045 5.29395H8.99503C6.95425 5.29395 5.29395 6.95433 5.29395 8.99511V15.0045C5.29395 17.0454 6.95425 18.7057 8.99503 18.7057H15.0045C17.0454 18.7057 18.7057 17.0453 18.7057 15.0045V8.99511C18.7058 6.95433 17.0454 5.29395 15.0045 5.29395ZM17.5158 15.0045C17.5158 16.3893 16.3893 17.5158 15.0045 17.5158H8.99503C7.61039 17.5158 6.4839 16.3893 6.4839 15.0045V8.99511C6.4839 7.61047 7.61039 6.4839 8.99503 6.4839H15.0045C16.3892 6.4839 17.5158 7.61047 17.5158 8.99511V15.0045H17.5158Z"
        fill="#E9E9E9"
      />
      <path
        d="M12.0008 8.54395C10.0952 8.54395 8.54492 10.0942 8.54492 11.9998C8.54492 13.9053 10.0952 15.4555 12.0008 15.4555C13.9064 15.4555 15.4567 13.9053 15.4567 11.9998C15.4567 10.0942 13.9064 8.54395 12.0008 8.54395ZM12.0008 14.2655C10.7514 14.2655 9.73488 13.2491 9.73488 11.9997C9.73488 10.7503 10.7513 9.73382 12.0008 9.73382C13.2502 9.73382 14.2667 10.7503 14.2667 11.9997C14.2667 13.2491 13.2502 14.2655 12.0008 14.2655Z"
        fill="#E9E9E9"
      />
      <path
        d="M15.6002 7.53467C15.3709 7.53467 15.1457 7.62748 14.9838 7.79011C14.8211 7.95195 14.7275 8.17724 14.7275 8.4073C14.7275 8.63665 14.8211 8.86187 14.9838 9.02449C15.1456 9.18633 15.3709 9.27994 15.6002 9.27994C15.8302 9.27994 16.0547 9.18633 16.2174 9.02449C16.38 8.86187 16.4728 8.63657 16.4728 8.4073C16.4728 8.17724 16.38 7.95195 16.2174 7.79011C16.0555 7.62748 15.8302 7.53467 15.6002 7.53467Z"
        fill="#E9E9E9"
      />
    </svg>
  ) : (
    <></>
  );
}
export default SocialIcon;
