import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material";
import { PostHogProvider } from "posthog-js/react";
import App from "./App";
import { GlobalProvider } from "./GlobalContext";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "./asset/css/base.css";
import "./index.css";
import "./asset/css/style.scss";

const theme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#e3c050",
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("six-app-directory"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <HelmetProvider>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            session_recording: {
              maskAllInputs: false,
              recordCrossOriginIframes: true,
            },
            enable_heatmaps: true,
          }}
        >
          <ThemeProvider theme={theme}>
            <GlobalProvider>
              <App />
            </GlobalProvider>
          </ThemeProvider>
        </PostHogProvider>
      </HelmetProvider>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
