import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout";
import HomePage from "./pages/HomePage";
import PricingPage from "./pages/PricingPage";
import RefreshPage from "./pages/RefreshPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
        </Route>
        <Route path="/R_C_P" element={<RefreshPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
