import React from "react";
import { Button } from "@mui/material";

const HomeEventsEmpty = () => {
  return (
    <div className="app6ix-layout-home-events-empty">
      <div className="app6ix-layout-home-events-empty-text">
        There are no upcoming events at this time. <br />
        But you can view previous events
      </div>
      <Button
        href="https://events.6ix.com/explore/"
        variant="outlined"
        className="app6ix-btn app6ix-btn-primary"
      >
        VIEW previous EVENTS
      </Button>
    </div>
  );
};

export default HomeEventsEmpty;
