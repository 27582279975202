import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Layout = () => {
  return window.location === window.parent.location ? (
    <React.Fragment>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme="dark"
        position="bottom-left"
      />
      <Header />
      <div className="app6ix-layout-content app6ix-layout-content-space">
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  ) : null;
};

export default Layout;
