import React from "react";
export function ArrowIcon(props) {
  return props?.type === "plus" ? (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.850391 6.60001L7.15039 6.60001L4.00039 3.10001L0.850391 6.60001Z"
        fill="#11B585"
      />
    </svg>
  ) : props?.type === "minus" ? (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14961 3.10001L0.849609 3.10001L3.99961 6.60001L7.14961 3.10001Z"
        fill="#E64135"
      />
    </svg>
  ) : (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91699 7H11.0837"
        stroke="#8C8E95"
        strokeWidth="1.169"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 2.91666L11.0833 6.99999L7 11.0833"
        stroke="#8C8E95"
        strokeWidth="1.169"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowIcon;
