import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ArrowIcon } from "../../icons";
import { MARKET_SERVICES } from "../../services";

const HomeMarkets = () => {
  const dataFetched = useRef(false);
  const [marketData, setMarketData] = useState("");
  useEffect(() => {
    if (dataFetched?.current) return;
    dataFetched.current = true;

    MARKET_SERVICES.market()
      .then(data => {
        if (data?.data?.length > 0) {
          setMarketData(data?.data);
        }
      })
      .catch(err => {});
  }, [dataFetched]);
  return marketData ? (
    <section className="app6ix-layout-home-section app6ix-layout-home-markets">
      <div className="app6ix-layout-home-section-header">
        <div className="app6ix-layout-home-section-header-heading">
          Track All Markets
        </div>
      </div>
      <div className="app6ix-layout-home-markets-list">
        {marketData?.map((listItem, key) => (
          <div key={key} className="app6ix-layout-home-markets-list-item">
            <div className="app6ix-layout-home-markets-list-item-label">
              {listItem?.label}
            </div>
            <div className="app6ix-layout-home-markets-list-item-value">
              <div className="app6ix-layout-home-markets-list-item-value-current">
                <NumericFormat
                  value={listItem?.currentValue}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={4}
                />
              </div>
              {listItem?.relativeValue && (
                <div
                  className={`app6ix-layout-home-markets-list-item-value-percent ${listItem?.relativeValue > 0 ? "text-success" : "text-danger"}`}
                >
                  <ArrowIcon
                    type={listItem?.relativeValue > 0 ? "plus" : "minus"}
                  />
                  {Math.abs(listItem?.relativeValue)?.toFixed(2)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  ) : null;
};

export default HomeMarkets;
