//import { Link } from "react-router-dom";

const FooterReffer = () => {
  return (
    <div className="app6ix-layout-footer-reffer">
      <a
        href="mailto:friends@6ix.com"
        className="app6ix-layout-footer-reffer-link"
      >
        <div className="app6ix-layout-footer-reffer-link-icon">New</div>
        <div className="app6ix-layout-footer-reffer-link-text">
          Refer an issuer & get a gift
        </div>
      </a>

      <a
        href="mailto:friends@6ix.com"
        className="app6ix-layout-footer-reffer-link"
      >
        <div className="app6ix-layout-footer-reffer-link-icon">New</div>
        <div className="app6ix-layout-footer-reffer-link-text">
          Refer a user & get a gift
        </div>
      </a>
    </div>
  );
};

export default FooterReffer;
