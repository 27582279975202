import React, { useEffect, useState } from "react";
import Moment from "moment";
import { CalenderIcon, ClockIcon } from "../../../icons";

const HomeEventsItem = props => {
  const { listItem } = props;

  const [hostedLabel, setHostedLabel] = useState("");
  useEffect(() => {
    let hostText = "";
    if (listItem?.hostedBy?.length > 0) {
      for (let value of listItem.hostedBy) {
        if (value?.allow) {
          hostText += hostText ? ", " + value?.orgName : value?.orgName;
        }
      }
    }
    setHostedLabel(hostText);
  }, [listItem]);

  return (
    <div className="app6ix-layout-home-events-items-box">
      <a
        className="app6ix-layout-home-events-items-box-inner"
        href={listItem?.eventMeetingLink}
      >
        <div
          className="app6ix-layout-home-events-items-box-media"
          title={listItem.eventName}
        >
          <img src={listItem.eventCover} alt={listItem.eventName} />
        </div>
        <div className="app6ix-layout-home-events-items-box-content">
          <div className="app6ix-layout-home-events-items-box-hosted">
            {hostedLabel}
          </div>
          <div
            className="app6ix-layout-home-events-items-box-heading"
            title="How Can Explorers Make Investor Due Diligence Easy? Feat. Atrium Research"
          >
            {listItem.eventName}
          </div>
          <div className="app6ix-layout-home-events-items-box-date">
            <div className="app6ix-layout-home-events-items-box-date-text">
              <CalenderIcon />

              {listItem?.eventDate
                ? Moment(listItem?.eventDate).format("ddd, MMM DD, YYYY")
                : "TBD"}
            </div>
            <div className="app6ix-layout-home-events-items-box-date-text">
              <ClockIcon />
              {Moment(listItem?.eventDate).format("hh:mm A")}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default HomeEventsItem;
