import React from "react";
import { Link } from "react-router-dom";

const HeaderTop = () => {
  return (
    <div className="app6ix-layout-header-top">
      <div className="app6ix-layout-header-top-text">
        6ix helps you succeed in the capital markets:
      </div>
      <Link to="/pricing" className="app6ix-layout-header-top-link">
        See Pricing
      </Link>
    </div>
  );
};

export default HeaderTop;
