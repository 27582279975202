import FooterBottom from "./FooterBottom";
import FooterCols from "./FooterCols";
import FooterDisclaimer from "./FooterDisclaimer";
import FooterReffer from "./FooterReffer";
import FooterTop from "./FooterTop";
import "./style.scss";

const Footer = () => {
  return (
    <div className="app6ix-layout-footer">
      <div className="app6ix-layout-content-container">
        <div className="app6ix-layout-footer-line"></div>
        <FooterTop />
        <div className="app6ix-layout-footer-line"></div>
        <FooterCols />
        <div className="app6ix-layout-footer-line"></div>
        <FooterReffer />
        <div className="app6ix-layout-footer-line"></div>
        <FooterDisclaimer />
        <div className="app6ix-layout-footer-line"></div>
        <FooterBottom />
      </div>
    </div>
  );
};

export default Footer;
