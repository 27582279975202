import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, FormControl, TextField } from "@mui/material";
import { useGlobalValue } from "../../../GlobalContext";
import { SESSION_AUTH } from "../../../helper/auth";
import { SendIcon } from "../../../icons";

const HomeAi = () => {
  const { setLoginRequest } = useGlobalValue();
  const { isUserSession } = SESSION_AUTH();
  const [serarchQuery, setSerarchQuery] = useState("");
  const [searchRequest, setSearchRequest] = useState(false);
  const onSubmit = () => {
    if (!serarchQuery) {
      return toast("Enter your query");
    }
    if (isUserSession) {
      window.location.href = "https://ai.6ix.com/?q_ai=" + serarchQuery;
    } else {
      setSearchRequest(true);
      setLoginRequest(true);
    }
  };
  useEffect(() => {
    if (isUserSession && searchRequest && serarchQuery) {
      window.location.href = "https://ai.6ix.com/?q_ai=" + serarchQuery;
      setSearchRequest(false);
    }
  }, [isUserSession, serarchQuery, searchRequest]);

  return (
    <section className="app6ix-layout-home-section app6ix-layout-home-ai">
      <div className="app6ix-layout-home-ai-heading">
        Research Anything With 6ix AI
      </div>

      <FormControl fullWidth className="app6ix-layout-home-ai-search">
        <TextField
          multiline={true}
          minRows={1}
          maxRows={3}
          name="q_ai"
          required
          value={serarchQuery}
          onChange={e => {
            setSerarchQuery(e.target?.value);
          }}
          className="app6ix-layout-home-ai-search-input"
          placeholder="Ask 6ix AI anything!"
          onKeyDown={ev => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              onSubmit();
            }
          }}
        />
        <Button
          startIcon={<SendIcon active={serarchQuery} />}
          className="app6ix-layout-home-ai-search-btn"
          disabled={!serarchQuery}
          onClick={onSubmit}
        />
      </FormControl>
    </section>
  );
};

export default HomeAi;
