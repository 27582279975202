import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { PlusIcon } from "../../icons";

const HeaderSidebar = props => {
  const { setOpenSidebar } = props;
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    setActiveClass("active");
    return () => null;
  }, []);

  const closeSideBar = () => {
    setOpenSidebar(false);
  };

  return (
    <>
      <div
        className={`app6ix-layout-sidebar app6ix-layout-sidebar-space ${activeClass}`}
      >
        <div className="app6ix-layout-sidebar-inner">
          <div className="app6ix-layout-sidebar-header">
            <Button
              variant="outlined"
              href="https://6ix.com/demo/"
              className="app6ix-btn app6ix-btn-primary app6ix-btn-md w-100"
              startIcon={<PlusIcon />}
            >
              Book a demo
            </Button>
          </div>

          <div className="app6ix-layout-sidebar-body">
            <a
              href="https://6ix.com/solutions-for-companies"
              className="app6ix-layout-sidebar-link"
              onClick={closeSideBar}
            >
              Solutions for Companies
            </a>
            <a
              href="https://6ix.com/roi"
              className="app6ix-layout-sidebar-link"
              onClick={closeSideBar}
            >
              Customers and ROI
            </a>
            <a
              href="https://6ix.com/magazine"
              className="app6ix-layout-sidebar-link"
              onClick={closeSideBar}
            >
              Resources & Magazine
            </a>
            <a
              href="https://6ix.com/about"
              className="app6ix-layout-sidebar-link"
              onClick={closeSideBar}
            >
              About 6ix
            </a>
          </div>
        </div>
      </div>
      <div
        className="app6ix-layout-sidebar-overlay"
        onClick={closeSideBar}
      ></div>
    </>
  );
};

export default HeaderSidebar;
