import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../asset/images/logo.png";

const HeaderLogo = () => {
  return (
    <React.Fragment>
      <Link title="6ix App" to="/" className="app6ix-layout-header-logo">
        <img className="app6ix-layout-header-logo-img" src={Logo} alt="logo" />
      </Link>
    </React.Fragment>
  );
};

export default HeaderLogo;
