import React from "react";
import HomeAi from "./Ai/HomeAi";
import HomeEvents from "./Events/HomeEvents";
import HomeMarkets from "./HomeMarkets";
import "./style.scss";

const Home = () => {
  return (
    <div className="app6ix-layout-content-container">
      <HomeAi />
      <HomeMarkets />
      <HomeEvents />
    </div>
  );
};

export default Home;
