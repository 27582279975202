import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { SESSION_AUTH } from "../../../helper/auth";
import { ArrowIcon } from "../../../icons";
import FullPageLoader from "../../Common/FullPageLoader";
import HomeEventsEmpty from "./HomeEventsEmpty";
import HomeEventsItem from "./HomeEventsItem";
import { EVENT_SERVICES } from "../../../services";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeEvents = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetched = useRef(false);
  const [EVENTS_LIST, setEVENTS_LIST] = useState("");
  const [loading, setLoading] = useState(true);
  const [settings, setsettings] = useState(true);
  useEffect(() => {
    if (dataFetched?.current) return;
    dataFetched.current = true;

    EVENT_SERVICES.upcoming({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          if (data?.data?.length > 0) {
            setEVENTS_LIST(data?.data);
            setsettings({
              dots: true,
              infinite: data?.data?.length > 4,
              arrows: data?.data?.length > 3,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 1,
              dotsClass: "app6ix-layout-home-events-items-dots",
              centerMode: data?.data?.length > 4,
              responsive: [
                {
                  breakpoint: 900,
                  settings: {
                    slidesToShow: 2,
                    arrows: data?.data?.length > 2,
                    centerMode: data?.data?.length > 3,
                    infinite: data?.data?.length > 3,
                  },
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                    arrows: data?.data?.length > 1,
                    centerMode: data?.data?.length > 2,
                    infinite: data?.data?.length > 2,
                  },
                },
              ],

              onInit: function () {
                try {
                  slideButtons();
                  setTimeout(() => {
                    slideButtons();
                  }, 1000);
                } catch (err) {
                  //console.log(err)
                }
              },
            });
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  }, [dataFetched, LOGOUT_USER_SESSION, isUserSession]);

  const slideButtons = () => {
    let prevBtn = document.querySelector(
      ".app6ix-layout-home-events-items .slick-arrow.slick-prev"
    );
    let nextBtn = document.querySelector(
      ".app6ix-layout-home-events-items .slick-arrow.slick-next"
    );
    let dotsBtn = document.querySelector(
      ".app6ix-layout-home-events-items-dots"
    );

    if (prevBtn && dotsBtn) {
      dotsBtn.appendChild(prevBtn);
    }
    if (nextBtn && dotsBtn) {
      dotsBtn.appendChild(nextBtn);
    }
  };

  return loading ? (
    <FullPageLoader normal={true} />
  ) : (
    <section className="app6ix-layout-home-section app6ix-layout-home-events">
      <div className="app6ix-layout-home-section-header">
        <div className="app6ix-layout-home-section-header-heading">
          Attend Exclusive Events
        </div>
        {EVENTS_LIST && (
          <a
            className="app6ix-layout-home-section-header-action"
            href="https://events.6ix.com/explore/"
          >
            View All Events <ArrowIcon />
          </a>
        )}
      </div>

      {EVENTS_LIST ? (
        settings && (
          <Slider className="app6ix-layout-home-events-items" {...settings}>
            {EVENTS_LIST.map((listItem, key) => {
              return <HomeEventsItem listItem={listItem} key={key} />;
            })}
          </Slider>
        )
      ) : (
        <HomeEventsEmpty />
      )}
    </section>
  );
};

export default HomeEvents;
