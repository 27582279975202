import { SocialIcon } from "../../icons";
import { SOCIAL_LIST } from "../../helper/constants";

const FooterBottom = () => {
  return (
    <div className="app6ix-layout-footer-bottom">
      <div className="app6ix-layout-footer-bottom-col app6ix-layout-footer-bottom-social">
        {SOCIAL_LIST.map((item, key) => (
          <a
            key={key}
            href={item?.link}
            title={item?.title}
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon type={item?.icon} />
          </a>
        ))}
      </div>
      <div className="app6ix-layout-footer-bottom-col app6ix-layout-footer-bottom-links">
        <a href="https://6ix.com/privacy-policy">Privacy Policy</a>
        <a href="https://6ix.com/terms">Terms of Service</a>
        <p>&copy; 6ix Inc - 2024</p>
      </div>
    </div>
  );
};

export default FooterBottom;
