import Logo from "../../asset/images/logo.png";
const FooterTop = () => {
  return (
    <div className="app6ix-layout-footer-top">
      <div className="app6ix-layout-footer-top-logo">
        <img src={Logo} alt="6ix app" />
      </div>
      <div className="app6ix-layout-footer-top-links">
        <a href="https://6ix.com/about">About </a>
        <a href="https://6ix.com/invest">Invest </a>
        <a href="https://6ix.com/raise">Raise </a>
        <a href="https://6ix.com/host">Host </a>
        <a href="https://6ix.com/demo">Demo </a>
        <a href="https://6ix.com/pricing">Pricing </a>
        <a href="https://6ix.com/security">Security </a>
      </div>
    </div>
  );
};

export default FooterTop;
