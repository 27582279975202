import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [loginRequest, setLoginRequest] = useState(0);
  const [appLang, setAppLang] = useState("en");

  return (
    <GlobalContext.Provider
      value={{
        loginRequest,
        setLoginRequest,
        appLang,
        setAppLang,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValue = () => useContext(GlobalContext);
