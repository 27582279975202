const FooterDisclaimer = () => (
  <div className="app6ix-layout-footer-disclaimer">
    <div className="app6ix-layout-footer-disclaimer-heading">
      Disclaimer of Liability
    </div>
    <div className="app6ix-layout-footer-disclaimer-text w-100">
      <div className="mb-15">
        6ix Inc. (“6ix”) licenses software to publicly traded issuers of
        securities (“Issuers” and each an “Issuer”) which can be used by Issuers
        to communicate with current and prospective investors (“Investors” and
        each an “Investor”) on the online platform maintained by 6ix Inc. at
        www.6ix.com (the “Platform”). Each Issuer is solely responsible for all
        content relating to the Issuer on the Platform, including without
        limitation all investor summits presented by the Issuer and all summary
        information about the Issuer on the Platform.
      </div>
      <div className="mb-15">
        All content provided on the Platform is for informational and
        educational purposes only and should not be construed as investment
        advice or an offer or solicitation in respect to any products or
        services. The content presented on the Platform should not be used as
        the basis for any investment decision, and does not purport to provide
        any legal, tax or accounting advice. There are inherent risks involved
        with investing in Issuers, as set out in the public disclosure record of
        each Issuer. Issuers are not responsible for revising or updating any
        information that they present on the Platform.
      </div>
      <div className="mb-15">
        Investors should refer to the continuous disclosure documents filed by
        each Issuer under applicable securities laws, including risk factors and
        warnings regarding “forward looking information”. Issuers are solely
        responsible for compliance with applicable securities laws, and 6ix
        makes no representations and provides no assurances to Investors
        regarding the accuracy or truthfulness of information presented by
        Issuers on the Platform or in their public disclosure records.
      </div>
      <div className="mb-15">
        6ix is not licensed to trade, deal or advise in securities in any
        jurisdiction. 6ix does not recommend or endorse any Issuer on the
        Platform, nor does 6ix verify the accuracy of any information presented
        by Issuers to Investors on the Platform or in their public disclosure
        records. 6ix’s sole responsibility as the operator of the Platform is to
        provide software to Issuers and Investors through which Issuers and
        Investors communicate directly with each other without any
        intermediation or intervention by 6ix other than in respect of
        technical, logistical or advertising and marketing support. Issuers pay
        6ix a licensing fee for use of the Platform, and 6ix may also receive
        payment from Issuers to pay for advertising on third-party websites. 6ix
        and its principals may make and hold investments in securities of
        Issuers on the Platform and 6ix may offer reduced rates for its software
        services to Issuers in which 6ix and/or its principals are invested.
      </div>
      <div className="mb-15">
        In addition to the information provided by Issuers elsewhere on the
        Platform, 6ix’s markets module provides information and content from
        Finnhub Stock API and TradingView on a wide range of publicly traded
        issuers of securities (the “MM Content”). The MM Content is not reviewed
        or vetted by 6ix. 6ix does not represent or warrant that any of the MM
        Content is accurate, reliable, current, complete or appropriate for any
        investor’s needs. The MM Content may be wrong, incomplete, interrupted
        or delayed.{" "}
      </div>
      <div className="mb-15">
        6ix may discontinue or change any service or feature on the Platform,
        including the provision of the MM Content, at any time and without
        notice. 6ix will not be liable if for any reason all or any part of the
        Platform is unavailable at any time or for any period.
      </div>
      <div className="mb-15">
        The markets module may include links to third party websites. Some of
        these sites may contain materials that are objectionable, unlawful, or
        inaccurate. 6ix shall not be held liable for any activities that occur
        on any third party website accessed through links on 6ix’s markets
        module. 6ix provides these links as a convenience, without review or
        vetting by 6ix, and does not endorse the content or services offered by
        these other sites. 6ix is not liable for any information provided by any
        third party website or for any activities conducted with parties there.
        Neither 6ix nor anyone associated with 6ix represents or warrants that
        third party websites or the servers that make them available are free of
        viruses or other harmful components.
      </div>
      <div className="mb-15">
        ALL CONTENT ON THE PLATFORM, INCLUDING THAT BY THE ISSUERS, THE MM
        CONTENT, AND LINKS TO THIRD PARTY WEBSITES, IS PROVIDED “AS IS” AND “AS
        AVAILABLE”. 6IX DOES NOT GUARANTEE THE ACCURACY OF ITS CONTENT. BY USING
        THE PLATFORM, EACH INVESTOR AGREES TO ACCEPT ANY RISKS ASSOCIATED WITH
        THE USE OF THE PLATFORM AND ACKNOWLEDGES THAT 6IX IS NOT RESPONSIBLE FOR
        ANY CONTENT ON THE PLATFORM, INCLUDING THAT PRESENTED BY OR RELATING TO
        ISSUERS.
      </div>
      <div className="mb-15">
        6IX DOES NOT WARRANT THAT THE PROVISION OF INFORMATION ON THE PLATFORM
        WILL BE ERROR-FREE, TIMELY, COMPLETE OR ACCURATE. USE OF THE PLATFORM
        AND RELIANCE THEREON IS AT INVESTORS’ SOLE RISK. 6IX WILL NOT BE IN ANY
        WAY BE LIABLE TO ANY INVESTOR OR ISSUER OR ANY OTHER ENTITY OR PERSON
        FOR ANY INACCURACIES, ERRORS, OMISSIONS, DELAYS, DAMAGES, CLAIMS,
        LIABILITIES OR LOSSES, REGARDLESS OF CAUSE, IN OR ARISING FROM THE USE
        OF THE PLATFORM.
      </div>
      <div className="mb-15">
        IN NO EVENT WILL 6IX BE LIABLE FOR ANY DAMAGES, INCLUDING WITHOUT
        LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
        DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE USE OF THE
        PLATFORM BY INVESTORS AND/OR ISSUERS EVEN IF 6IX IS ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES. FURTHER, 6IX SHALL NOT
        BE LIABLE IN ANY MANNER FOR THE PRODUCT OR SERVICES OF ANYONE WHO
        REDISTRIBUTES THE INFORMATION PROVIDED ON THE PLATFORM, AND SUCH
        REDISTRIBUTION IS EXPRESSLY PROHIBITED.
      </div>
    </div>
  </div>
);

export default FooterDisclaimer;
