import React from "react";
import Home from "../components/Home";
const HomePage = () => {
  return (
    <div className="app6ix-layout-content-page app6ix-layout-home">
      <Home />
    </div>
  );
};

export default HomePage;
